import React from "react";
import Header from "../../components/Header/Header";

export const TradeIn = () => {
  return (
    <div>
      <Header />;
    </div>
  );
};
